
export const groupsRows = (groups, months) => {
  const dataTypes = ["outcomes", "incomes", "dph", "investments", "loanBank", "loanGroup"]; // Sale and rent removed based on client request
  let rows;
  let validGroupRows = [];
  let specialGroupRows = [];
  let sumRows = [];

  // Initialize objects to store sums for overall calculations
  const overallSums = {};
  const totalSums = {};

  months.forEach(month => {
    overallSums[`assumptions_${month}`] = {};
    overallSums[`reality_${month}`] = {};
    dataTypes.forEach(type => {
      overallSums[`assumptions_${month}`][type] = 0;
      overallSums[`reality_${month}`][type] = 0;
    });
  });

  dataTypes.forEach(type => {
    totalSums[type] = {
      sumAssumptions: 0,
      sumReality: 0,
      totalAssumptionValues: 0,
      totalValues: 0
    };
  });

  const groupData = {};

  groups.forEach(group => {
    const {group: groupName, monthlyData, assumptionData, totalValues, totalAssumptionValues} = group;

    // Handle special cases for group names
    let groupLabel = groupName;
    if (groupName === undefined) {
      groupLabel = 'Skupina "undefined"';
    } else if (groupName === null) {
      groupLabel = 'Skupina "null"';
    } else if (groupName === '') {
      groupLabel = 'Ostatní';
    }

    if (!groupData[groupLabel]) {
      groupData[groupLabel] = {
        monthlyDataByMonth: {},
        assumptionDataByMonth: {},
        totalValues: {
          outcomes: 0,
          incomes: 0,
          dph: 0,
          investments: 0,
          sale: 0,
          rent: 0,
          loanBank: 0,
          loanGroup: 0
        },
        totalAssumptionValues: {
          outcomes: 0,
          incomes: 0,
          dph: 0,
          investments: 0,
          sale: 0,
          rent: 0,
          loanBank: 0,
          loanGroup: 0
        }
      };
    }

    // Aggregate monthly data
    Object.entries(monthlyData).forEach(([monthYear, data]) => {
      const { outcomes, incomes, dph, investments, sale, rent, loanBank, loanGroup } = data;

      if (!groupData[groupLabel].monthlyDataByMonth[monthYear]) {
        groupData[groupLabel].monthlyDataByMonth[monthYear] = {
          outcomes: 0,
          incomes: 0,
          dph: 0,
          investments: 0,
          sale: 0,
          rent: 0,
          loanBank: 0,
          loanGroup: 0
        };
      }

      groupData[groupLabel].monthlyDataByMonth[monthYear].outcomes += outcomes;
      groupData[groupLabel].monthlyDataByMonth[monthYear].incomes += incomes;
      groupData[groupLabel].monthlyDataByMonth[monthYear].dph += dph;
      groupData[groupLabel].monthlyDataByMonth[monthYear].investments += investments;
      groupData[groupLabel].monthlyDataByMonth[monthYear].sale += sale;
      groupData[groupLabel].monthlyDataByMonth[monthYear].rent += rent;
      groupData[groupLabel].monthlyDataByMonth[monthYear].loanBank += loanBank;
      groupData[groupLabel].monthlyDataByMonth[monthYear].loanGroup += loanGroup;
    });


    // Aggregate assumption data
    Object.entries(assumptionData).forEach(([monthYear, data]) => {
      const { outcomes, incomes, dph, investments, sale, rent, loanBank, loanGroup } = data;

      if (!groupData[groupLabel].assumptionDataByMonth[monthYear]) {
        groupData[groupLabel].assumptionDataByMonth[monthYear] = {
          outcomes: 0,
          incomes: 0,
          dph: 0,
          investments: 0,
          sale: 0,
          rent: 0,
          loanBank: 0,
          loanGroup: 0
        };
      }

      groupData[groupLabel].assumptionDataByMonth[monthYear].outcomes += outcomes;
      groupData[groupLabel].assumptionDataByMonth[monthYear].incomes += incomes;
      groupData[groupLabel].assumptionDataByMonth[monthYear].dph += dph;
      groupData[groupLabel].assumptionDataByMonth[monthYear].investments += investments;
      groupData[groupLabel].assumptionDataByMonth[monthYear].sale += sale;
      groupData[groupLabel].assumptionDataByMonth[monthYear].rent += rent;
      groupData[groupLabel].assumptionDataByMonth[monthYear].loanBank += loanBank;
      groupData[groupLabel].assumptionDataByMonth[monthYear].loanGroup += loanGroup;
    });


    // Aggregate total values
    Object.keys(totalValues).forEach(type => {
      groupData[groupLabel].totalValues[type] += totalValues[type];
    });

    // Aggregate total assumption values
    Object.keys(totalAssumptionValues).forEach(type => {
      groupData[groupLabel].totalAssumptionValues[type] += totalAssumptionValues[type];
    });
  });

  // Prepare rows for each group
  Object.keys(groupData).forEach(groupLabel => {
    const group = groupData[groupLabel];
    const monthlyDataByMonth = group.monthlyDataByMonth;
    const assumptionDataByMonth = group.assumptionDataByMonth;

    dataTypes.forEach(dataType => {
      let row = {
        id: `${groupLabel}-${dataType}`,
        hierarchy: [groupLabel, translateDataType(dataType)]
      };

      let sumAssumptions = 0;
      let sumReality = 0;

      months.forEach(month => {
        const assumptionValue = (assumptionDataByMonth[month] && assumptionDataByMonth[month][dataType]) || 0;
        const realityValue = (monthlyDataByMonth[month] && monthlyDataByMonth[month][dataType]) || 0;

        row[`assumptions_${month}`] = assumptionValue;
        row[`reality_${month}`] = realityValue;

        sumAssumptions += assumptionValue;
        sumReality += realityValue;

        overallSums[`assumptions_${month}`][dataType] += assumptionValue;
        overallSums[`reality_${month}`][dataType] += realityValue;
      });

      row['sum_assumptions'] = sumAssumptions;
      row['sum_reality'] = sumReality;
      row['total_assumption_values'] = group.totalAssumptionValues[dataType] || 0;
      row['total_values'] = group.totalValues[dataType] || 0;

      totalSums[dataType].sumAssumptions += sumAssumptions;
      totalSums[dataType].sumReality += sumReality;
      totalSums[dataType].totalAssumptionValues += row['total_assumption_values'];
      totalSums[dataType].totalValues += row['total_values'];

      // Separate rows into valid and special groups
      if (groupLabel.startsWith('Faktury')) {
        specialGroupRows.push(row);
      } else {
        validGroupRows.push(row);
      }
    });
  });

  // Add final overall sum rows for each dataType
  dataTypes.forEach(dataType => {
    const sumRow = {id: `sum-${dataType}`, hierarchy: ["Celkem", translateDataType(dataType)]};
    let totalSumAssumptions = 0;
    let totalSumReality = 0;

    months.forEach(month => {
      sumRow[`assumptions_${month}`] = overallSums[`assumptions_${month}`][dataType];
      sumRow[`reality_${month}`] = overallSums[`reality_${month}`][dataType];
      totalSumAssumptions += overallSums[`assumptions_${month}`][dataType];
      totalSumReality += overallSums[`reality_${month}`][dataType];
    });

    sumRow['sum_assumptions'] = totalSumAssumptions;
    sumRow['sum_reality'] = totalSumReality;
    sumRow['total_assumption_values'] = totalSums[dataType].totalAssumptionValues;
    sumRow['total_values'] = totalSums[dataType].totalValues;

    sumRows.push(sumRow);
  });

  // Add a cash flow row within the sum hierarchy
  const cashFlowRow = {id: 'cashFlow', hierarchy: ["Čistý finanční tok"]};
  let totalCashFlowAssumptions = 0;
  let totalCashFlowReality = 0;
  let totalCashFlowAssumptionValues
  let totalCashFlowValues;

  months.forEach(month => {
    const incomesAssumptions = overallSums[`assumptions_${month}`]['incomes'] || 0;
    const outcomesAssumptions = overallSums[`assumptions_${month}`]['outcomes'] || 0;
    const incomesReality = overallSums[`reality_${month}`]['incomes'] || 0;
    const outcomesReality = overallSums[`reality_${month}`]['outcomes'] || 0;

    const cashFlowAssumptions = incomesAssumptions - outcomesAssumptions;
    const cashFlowReality = incomesReality - outcomesReality;

    cashFlowRow[`assumptions_${month}`] = cashFlowAssumptions;
    cashFlowRow[`reality_${month}`] = cashFlowReality;

    totalCashFlowAssumptions += cashFlowAssumptions;
    totalCashFlowReality += cashFlowReality;
  });

  totalCashFlowAssumptionValues = totalSums['incomes'].totalAssumptionValues - totalSums['outcomes'].totalAssumptionValues;
  totalCashFlowValues = totalSums['incomes'].totalValues - totalSums['outcomes'].totalValues;

  cashFlowRow['sum_assumptions'] = totalCashFlowAssumptions;
  cashFlowRow['sum_reality'] = totalCashFlowReality;
  cashFlowRow['total_assumption_values'] = totalCashFlowAssumptionValues;
  cashFlowRow['total_values'] = totalCashFlowValues;

  // Combine all rows and sort them
  rows = [...validGroupRows, ...specialGroupRows, ...sumRows];

  // Sort rows: valid names first, special cases next, "Celkem" last
  rows.sort((a, b) => {
    if (a.hierarchy[0] === 'Celkem') return 1;
    if (b.hierarchy[0] === 'Celkem') return -1;

    if (!a.hierarchy[0] && b.hierarchy[0]) return 1;
    if (a.hierarchy[0] && !b.hierarchy[0]) return -1;

    return a.hierarchy[0]?.localeCompare(b.hierarchy[0] || '') || 0;
  });

  rows.push(cashFlowRow);

  return rows;
};


const dataTypeTranslations = {
  incomes: "Příjmy",
  outcomes: "Výdaje",
  dph: "DPH",
  investments: "Investice",
  sale: "Prodej",
  rent: "Pronájem",
  loanBank: "Půjčka - banka",
  loanGroup: "Půjčka - skupina"
};

// Function to translate data types to Czech
function translateDataType(dataType) {
  return dataTypeTranslations[dataType] || dataType;
}